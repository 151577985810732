:root {
  --brand-primary-100: #ebedff;
  --brand-primary-200: #b8c5ff;
  --brand-primary-300: #84a3ff;
  --brand-primary-400: #5285ff;
  --brand-primary-500: #005efe;
  --brand-primary-600: #004ed6;
  --brand-primary-700: #003e94;
  --brand-primary-800: #002c5c;
  --brand-primary-900: #001a2e;

  --brand-neutral-100: #ffffff;
  --brand-neutral-200: #f2f4f7;
  --brand-neutral-300: #dee4ee;
  --brand-neutral-400: #bac4d9;
  --brand-neutral-500: #9babcd;
  --brand-neutral-600: #6c7a99;
  --brand-neutral-700: #364159;
  --brand-neutral-800: #152133;
  --brand-neutral-900: #0c1626;

  --color-transition: background ease 0.5s, border-color ease 0.5s, fill ease 0.5s, stroke ease 0.5s, stop-color ease 0.5s;

  --mockup-height: 400px;
  --mockup-width: 600px;
}

* {
  font-family: "Nunito Sans", Roboto, "Open Sans", Tahoma, Geneva,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  transition: var(--color-transition);
}

html,
body,
#root {
  height: 100%;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-17 17:15:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-top
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
            transform: rotateX(-80deg);
            opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
            transform: rotateX(-80deg);
            opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

